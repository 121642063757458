import { scaleMmToPixels } from '~/utils/configurator'
import { round } from './utils'
import { useBoundStore } from '~/store'

export interface ExtendedRail extends Rail {
  sectionUid: string
  protrudingRail: number
}

/**
 * Creates data for the distance labels between mounts on a rail.
 *
 * @param mounts - An array of mounts.
 * @param railAngle - The angle of the rail.
 * @returns An array of distance labels.
 */
export const getMountDistanceLabels = (
  mounts: Mount[],
  railAngle: number,
  isApproved: boolean
) => {
  const distanceLabels: DistanceLabel[] = []
  let prevPosition = { x: 0, y: 0 }

  mounts.forEach((mount) => {
    const position = { ...mount.position }
    const showLabel =
      railAngle === 0
        ? prevPosition.x === position.x
        : prevPosition.y === position.y
    const value =
      railAngle === 0
        ? position.y - prevPosition.y
        : position.x - prevPosition.x
    if (prevPosition.x === position.x || prevPosition.y === position.y) {
      distanceLabels.push({
        showLabel,
        startPosition: scaleMmToPixels(prevPosition),
        endPosition: scaleMmToPixels(position),
        value: round(value / 10, 1),
        type: 'mount',
        isApproved,
        orientation: railAngle === 0 ? 'vertical' : 'horizontal'
      })
    }
    prevPosition = mount.position
  })

  return distanceLabels
}

/**
 * Creates data for the distance labels between mounts on a rail.
 *
 * @param mounts - The array of mounts.
 * @param railAngle - The angle of the rail.
 * @returns An array of distance labels.
 */
export const getRailDistanceLabels = (
  mounts: Mount[],
  railAngle: number,
  isApproved: boolean
) => {
  const sortByProperty = railAngle === 0 ? 'y' : 'x'
  const mountsSorted = [...mounts].sort(
    (a, b) => a.position[sortByProperty] - b.position[sortByProperty]
  )

  const minXorY = mounts.reduce(
    (min, mount) => {
      const mountValue = mount.position[sortByProperty as keyof Position]
      return mountValue < min ? mountValue : min
    },
    mounts[0].position[sortByProperty as keyof Position]
  )

  const distanceLabels: DistanceLabel[] = []
  let prevPosition = { x: 0, y: 0 }
  let prevRowOrColumn = -1

  mountsSorted.forEach((mount) => {
    const position = { ...mount.position }
    const value =
      railAngle === 0
        ? position.x - prevPosition.x
        : position.y - prevPosition.y
    const showLabel = mount.position[sortByProperty] === minXorY
    if (
      (prevPosition.x === position.x || prevPosition.y === position.y) &&
      prevRowOrColumn === mount.rowOrColumn
    ) {
      distanceLabels.push({
        showLabel,
        startPosition: scaleMmToPixels(prevPosition),
        endPosition: scaleMmToPixels(position),
        value: scaleMmToPixels(value),
        type: 'rail',
        isApproved,
        orientation: railAngle === 0 ? 'vertical' : 'horizontal'
      })
    }
    prevPosition = mount.position
    prevRowOrColumn = mount.rowOrColumn
  })

  return distanceLabels
}

/**
 * Retrieves a panel area from the given array of panel areas based on the provided UID.
 * @param panelAreas - The array of panel areas to search in.
 * @param panelAreaUid - The UID of the panel area to retrieve.
 * @returns The panel area object matching the provided UID, or undefined if not found.
 */
export const getPanelAreaByUid = (
  // panelAreas: PanelArea[],
  panelAreaUid: string
) => {
  const { panelAreas } = useBoundStore.getState()
  return panelAreas.find((panelArea) => panelArea.uid === panelAreaUid)
}

/**
 * Retrieves rails from panel area sections and panel areas.
 * @param panelAreaSections - The array of panel area sections.
 * @param panelAreas - The array of panel areas.
 * @returns An object containing panel area rails.
 */
export const getPanelAreaRails = (
  panelAreaSections: PanelAreaSection[]
  // panelAreas: PanelArea[]
) => {
  return panelAreaSections.reduce(
    (acc, panelAreaSection) => {
      if (panelAreaSection.result && panelAreaSection.result.rails) {
        if (!acc[panelAreaSection.panelAreaUid]) {
          const panelArea = getPanelAreaByUid(
            // panelAreas,
            panelAreaSection.panelAreaUid
          )
          acc[panelAreaSection.panelAreaUid] = {
            rails: [],
            panelInfo: panelArea ? panelArea.panelInfo : null,
            edgeDistance: panelAreaSection
              ? panelAreaSection.result.edgeDistance
              : null,
            protrudingRail: panelAreaSection.result.protrudingRail,
            sectionUid: panelAreaSection ? panelAreaSection.uid : null
          }
        }
        const rails: ExtendedRail[] = panelAreaSection.result.rails.map(
          (rail) => ({
            ...rail,
            sectionUid: panelAreaSection.uid,
            protrudingRail: panelAreaSection.result.protrudingRail
          })
        )
        acc[panelAreaSection.panelAreaUid].rails.push(...rails)
      }
      return acc
    },
    {} as {
      [key: string]: {
        rails: any[]
        panelInfo: PanelInfo | null
        edgeDistance: number | null
        protrudingRail: number
        sectionUid: string | null
      }
    }
  )
}

/**
 * Generates an array of distance label data for mounts in panel area sections.
 *
 * @param panelAreaSections - An array of panel area sections containing results with mount data.
 * @param includeSupportPlates - A boolean indicating whether to include support plates in the calculation.
 * @returns An array of distance label data for mounts.
 */
export const getMountDistanceLabelsData = (
  panelAreaSections: PanelAreaSection[],
  includeSupportPlates: boolean
) => {
  let mountDistanceLabelsData: DistanceLabel[] = []
  panelAreaSections.forEach((panelAreaSection) => {
    const mounts = panelAreaSection.result.mounts
    const railAngle = panelAreaSection.result.railAngle
    const valueAxis = railAngle === 0 ? 'y' : 'x'
    let prevPosition: Position = { x: 0, y: 0 }
    mounts
      .filter((mount) =>
        includeSupportPlates ? true : mount.isSupportPlate !== true
      )
      .forEach((mount: Mount) => {
        const value = mount.position[valueAxis] - prevPosition[valueAxis]
        if (
          prevPosition.x === mount.position.x ||
          prevPosition.y === mount.position.y
        ) {
          mountDistanceLabelsData.push({
            showLabel: true,
            startPosition: scaleMmToPixels<Position>(prevPosition),
            endPosition: scaleMmToPixels<Position>(mount.position),
            value: scaleMmToPixels<number>(value),
            type: 'mount',
            isApproved: panelAreaSection.result.isApproved,
            orientation: railAngle === 0 ? 'vertical' : 'horizontal'
          })
        }
        prevPosition = mount.position
      })
  })
  return mountDistanceLabelsData
}

/**
 * Generates distance data for cross rails in the given panel area sections.
 *
 * @param panelAreaSections - An array of panel area sections containing results with rail information.
 * @returns An array of distance labels data for cross rails.
 *
 * The function processes each panel area section to identify cross rails and calculate
 * the distances between them. It then creates distance labels with the calculated distances
 * and other relevant information, such as start and end positions, orientation, and approval status.
 *
 */
export const getCrossRailDistanceData = (
  panelAreaSections: PanelAreaSection[]
) => {
  const data: DistanceLabel[] = []
  panelAreaSections.forEach((panelAreaSection) => {
    const panelAreaRails = panelAreaSection.result.rails
    const crossRails = panelAreaRails.filter((rail) => rail.type === 'cross')

    if (crossRails.length > 0) {
      const crossRailOrientation = crossRails[0].angle
        ? 'vertical'
        : 'horizontal'
      const railDistances = panelAreaSection.result.railDistances
      const crossRailOffset = railDistances.reduce((acc, distance, index) => {
        if (index === railDistances.length - 1) {
          return acc + distance / 2
        }
        return acc + distance
      }, 0)

      crossRails.forEach((rail, index) => {
        if (index === 0) return

        const previousRail = crossRails[index - 1]
        const distance =
          crossRailOrientation === 'horizontal'
            ? rail.startPosition.x - previousRail.startPosition.x
            : rail.startPosition.y - previousRail.startPosition.y

        const startPositionY =
          crossRailOrientation === 'horizontal'
            ? previousRail.startPosition.y + crossRailOffset
            : previousRail.startPosition.y
        const endPositionY =
          crossRailOrientation === 'horizontal'
            ? rail.startPosition.y + crossRailOffset
            : rail.startPosition.y

        const startPositionX =
          crossRailOrientation === 'vertical'
            ? previousRail.startPosition.x + crossRailOffset
            : previousRail.startPosition.x
        const endPositionX =
          crossRailOrientation === 'vertical'
            ? rail.startPosition.x + crossRailOffset
            : rail.startPosition.x

        data.push({
          showLabel: true,
          startPosition: scaleMmToPixels<Position>({
            x: startPositionX,
            y: startPositionY
          }),
          endPosition: scaleMmToPixels<Position>({
            x: endPositionX,
            y: endPositionY
          }),
          value: scaleMmToPixels<number>(distance),
          type: 'cross',
          isApproved: panelAreaSection.result.isApproved,
          orientation: crossRailOrientation
        })
      })
    }
  })
  return data
}

/**
 * Generates distance data for the main rails in each panel area section.
 *
 * @param panelAreaSections - An array of panel area sections, each containing rail information.
 * @returns An array of distance labels, each representing the distance between consecutive main rails.
 *
 * The function processes each panel area section to extract the main rails and calculate the distance
 * between consecutive main rails. The distance is calculated based on the orientation of the rails
 * (either horizontal or vertical). The resulting distance labels include information such as the start
 * and end positions, the distance value, the type of rail, approval status, and orientation.
 */
const getMainRailDistanceWithinSectionData = (
  panelAreaSections: PanelAreaSection[]
) => {
  const data: DistanceLabel[] = []
  panelAreaSections.forEach((panelAreaSection) => {
    const panelAreaRails = panelAreaSection.result.rails
    const mainRails = panelAreaRails.filter((rail) => rail.type === 'main')

    if (mainRails.length > 0) {
      const mainRailOrientation = mainRails[0].angle ? 'vertical' : 'horizontal'

      mainRails.forEach((rail, index) => {
        if (index === 0) return

        const previousRail = mainRails[index - 1]
        const distance =
          mainRailOrientation === 'horizontal'
            ? rail.startPosition.x - previousRail.startPosition.x
            : rail.startPosition.y - previousRail.startPosition.y
        const position =
          mainRailOrientation === 'horizontal' ? 'startPosition' : 'endPosition'

        data.push({
          showLabel: true,
          startPosition: scaleMmToPixels<Position>(previousRail[position]),
          endPosition: scaleMmToPixels<Position>(rail[position]),
          value: scaleMmToPixels<number>(distance),
          type: 'rail',
          isApproved: panelAreaSection.result.isApproved,
          orientation: mainRailOrientation
        })
      })
    }
  })
  return data
}

/**
 * Calculates the main rail distances between panel area sections.
 *
 * @param panelAreaSections - An array of panel area sections.
 * @returns An array of distance labels with information about the distances between main rails.
 *
 * The function performs the following steps:
 * 1. Groups the panel area sections by their `panelAreaUid`.
 * 2. For each group, further groups the sections by their position based on the rail angle.
 * 3. Identifies the edge sections for each group.
 * 4. Calculates the distance between consecutive edge sections' main rails.
 * 5. Creates distance labels with the calculated distances and other relevant information.
 *
 * The distance labels include:
 * - Whether to show the label.
 * - The start and end positions of the distance.
 * - The distance value.
 * - The type of the distance label (always 'rail').
 * - Whether the distance is approved based on the approval status of the sections.
 * - The orientation of the distance label.
 */
const getMainRailDistanceBetweenSectionData = (
  panelAreaSections: PanelAreaSection[]
) => {
  const data: DistanceLabel[] = []

  // @ts-ignore
  const groupedPanelAreaSections = Object.groupBy(
    panelAreaSections,
    (panelAreaSection: PanelAreaSection) => {
      return panelAreaSection.panelAreaUid
    }
  )

  const edgeSections: { [key: string]: PanelAreaSection[] } = {}

  Object.keys(groupedPanelAreaSections).forEach((key) => {
    const sections = groupedPanelAreaSections[key]
    if (sections === undefined) return
    // @ts-ignore
    const groupedSections = Object.groupBy<string, PanelAreaSection>(
      sections,
      (panelAreaSection: PanelAreaSection) => {
        return panelAreaSection.result.railAngle === 0
          ? panelAreaSection.position.x.toString()
          : panelAreaSection.position.y.toString()
      }
    )
    const tempEdgeSections: PanelAreaSection[] = []
    Object.keys(groupedSections).forEach((sectionKey) => {
      const sections = groupedSections[sectionKey]
      if (sections === undefined) return
      const sectionIndex =
        sections[0].result.railAngle === 0 ? 0 : sections.length - 1
      tempEdgeSections.push(sections[sectionIndex])
    })
    edgeSections[key] = tempEdgeSections
  })

  Object.keys(edgeSections).forEach((key) => {
    edgeSections[key].forEach((section, index) => {
      if (index === 0) return

      const prevSection = edgeSections[key][index - 1]

      const sectionMainRails = section.result.rails.filter(
        (rail) => rail.type === 'main'
      )
      const prevSectionMainRails = prevSection.result.rails.filter(
        (rail) => rail.type === 'main'
      )

      const rail = sectionMainRails[0]
      const previousRail = prevSectionMainRails[prevSectionMainRails.length - 1]

      const mainRailOrientation =
        sectionMainRails[0].angle === 0 ? 'vertical' : 'horizontal'

      const distance =
        mainRailOrientation === 'vertical'
          ? rail.startPosition.x - previousRail.startPosition.x
          : rail.startPosition.y - previousRail.startPosition.y

      const startPosition =
        mainRailOrientation === 'vertical'
          ? {
              x: previousRail.startPosition.x,
              y: Math.min(previousRail.startPosition.y, rail.startPosition.y)
            }
          : {
              x: Math.max(previousRail.endPosition.x, rail.endPosition.x),
              y: previousRail.endPosition.y
            }
      const endPosition =
        mainRailOrientation === 'vertical'
          ? {
              x: rail.startPosition.x,
              y: Math.min(previousRail.startPosition.y, rail.startPosition.y)
            }
          : {
              x: Math.max(previousRail.endPosition.x, rail.endPosition.x),
              y: rail.endPosition.y
            }

      data.push({
        showLabel: true,
        startPosition: scaleMmToPixels<Position>(startPosition),
        endPosition: scaleMmToPixels<Position>(endPosition),
        value: scaleMmToPixels<number>(distance),
        type: 'rail',
        isApproved: section.result.isApproved && prevSection.result.isApproved,
        orientation:
          mainRailOrientation === 'vertical' ? 'horizontal' : 'vertical'
      })
    })
  })
  return data
}

/**
 * Retrieves the main rail distance data for the given panel area sections.
 *
 * This function calculates the distance labels for the main rails within and between
 * the provided panel area sections and returns them as an array of `DistanceLabel` objects.
 *
 * @param panelAreaSections - An array of `PanelAreaSection` objects representing the sections of the panel area.
 * @returns An array of `DistanceLabel` objects containing the distance data for the main rails.
 */
export const getMainRailDistanceData = (
  panelAreaSections: PanelAreaSection[]
) => {
  const data: DistanceLabel[] = []
  data.push(...getMainRailDistanceWithinSectionData(panelAreaSections))
  data.push(...getMainRailDistanceBetweenSectionData(panelAreaSections))
  return data
}

/**
 * Generates an array of distance labels data for rail lengths based on the provided panel area sections.
 *
 * @param panelAreaSections - An array of panel area sections containing results with rail information.
 * @returns An array of distance label data containing information about the rail lengths.
 *
 */
export const getRailLengthsData = (panelAreaSections: PanelAreaSection[]) => {
  const data: DistanceLabel[] = []
  panelAreaSections.forEach((panelAreaSection) => {
    if (panelAreaSection.result.rails.length === 0) return
    const panelAreaRail = panelAreaSection.result.rails[0]
    data.push({
      showLabel: true,
      startPosition: scaleMmToPixels<Position>(panelAreaRail.startPosition),
      endPosition: scaleMmToPixels<Position>(panelAreaRail.endPosition),
      value: scaleMmToPixels<number>(
        panelAreaRail.angle === 0
          ? panelAreaRail.endPosition.y - panelAreaRail.startPosition.y
          : panelAreaRail.endPosition.x - panelAreaRail.startPosition.x
      ),
      type: 'rail-length',
      isApproved: panelAreaSection.result.isApproved,
      orientation: panelAreaRail.angle === 0 ? 'vertical' : 'horizontal'
    })
  })
  return data
}

import Configurator from './components/Configurator'
import { HashRouter, Route } from 'react-router-dom'
import { SentryRoutes } from './lib/sentry.ts'
import LoginPage from './components/pages/LoginPage'
import RegisterPage from './components/pages/RegisterPage'
import VerifyPage from './components/pages/VerifyPage'
import StartPage from './components/pages/StartPage'
import ProfilePage from './components/pages/ProfilePage'
import CartPage from './components/pages/CartPage'
import DialogHandler from './components/dialogs/DialogHandler'
import ForgotPasswordPage from './components/pages/ForgotPasswordPage'
import ResetPasswordSentPage from './components/pages/ResetPasswordSentPage'
import ProjectsPage from './components/pages/ProjectsPage.tsx'
import PositionPage from './components/pages/PositionPage'
import { authClient } from './http/api.ts'
import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useBoundStore } from './store.ts'
import SystemPage from './components/pages/SystemPage.tsx'

// import i18n needs to be bundled
import './lib/i18n.ts'
import Dialogs from './components/dialogs/Dialogs.tsx'
import MessagesPage from '~/components/pages/MessagesPage.tsx'
import MessagePage from '~/components/pages/MessagePage.tsx'
import TermsPage from '~/components/pages/TermsPage.tsx'

import { isMobile } from 'react-device-detect'
import IsMobileMessage from './components/IsMobileMessage.tsx'

function App() {
  useEffect(() => {
    const abortController = new AbortController()
    const getUser = async () =>
      await authClient
        .get('auth-user', {
          signal: abortController.signal
        })
        .then((res: AxiosResponse<{ user: User | null }>) => {
          useBoundStore.getState().setUser(res.data.user)
          setLoadingUser(false)
        })
    getUser().catch((res) => {
      if (res.code !== 'ERR_CANCELED') {
        setLoadingUser(false)
      }
    })
    return () => {
      setLoadingUser(true)
      abortController.abort()
    }
  }, [])

  const [loadingUser, setLoadingUser] = useState(true)

  if (isMobile) {
    return <IsMobileMessage />
  }

  return loadingUser ? null : (
    <>
      <HashRouter basename={import.meta.env.VITE_BASE_NAME}>
        <SentryRoutes>
          <Route
            path="/"
            Component={StartPage}
          />
          <Route
            path="/login"
            Component={LoginPage}
          />
          <Route
            path="/cart"
            Component={CartPage}
          />
          <Route
            path="/projects"
            Component={ProjectsPage}
          />
          <Route
            path="/messages"
            Component={MessagesPage}
          />
          <Route
            path="/messages/:slug"
            Component={MessagePage}
          />
          <Route
            path="/parallel/:configurationId?"
            Component={Configurator}
          />
          <Route
            path="/register"
            Component={RegisterPage}
          />
          <Route
            path="/forgotten-password"
            Component={ForgotPasswordPage}
          />
          <Route
            path="/reset-password-sent/:email"
            Component={ResetPasswordSentPage}
          />
          <Route
            path="/profile"
            Component={ProfilePage}
          />
          <Route
            path="/verify/:link/:email"
            Component={VerifyPage}
          />
          <Route
            path="/position"
            Component={PositionPage}
          />
          <Route
            path="/system"
            Component={SystemPage}
          />
          <Route
            path="/low-slope/:configurationId?"
            Component={Configurator}
          />
          <Route
            path="/terms-and-conditions"
            Component={TermsPage}
          />
        </SentryRoutes>
        <Dialogs />
      </HashRouter>
      <DialogHandler />
    </>
  )
}

export default App

import { t } from 'i18next'

const IsMobileMessage = () => {
  return (
    <div className="mx-auto flex h-full max-w-lg flex-col items-center justify-center px-8 text-center">
      <h1 className="mb-4 text-xl">{t('Verktyg ej tillgängligt')}</h1>
      <p>
        {t(
          'Vår konfigurator för solpanelsfästen stöds för närvarande inte på mobila enheter. För att kunna använda verktyget, vänligen besök samma länk via en desktop-enhet.'
        )}
      </p>
    </div>
  )
}

export default IsMobileMessage

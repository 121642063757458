import { useEffect, useState } from 'react'
import { solarPanelClient } from '../http/api'
import { useTranslation } from 'react-i18next'
import ExternalInput from './form/fields/ExternalInput'
import { useDebounce } from 'use-debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faAngleUp,
  faArrowDownToLine
} from '@fortawesome/sharp-regular-svg-icons'
import { StoreState, useBoundStore } from '../store'
import Button from './buttons/Button'
import ProgressBar from './ProgressBar'
import { cn } from '~/lib/utils'
import { Tooltip } from 'react-tooltip'
import * as Sentry from '@sentry/react'
import ProjectDropDown from './ProjectDropDown'
import {
  handleLoadMoreProjectsResponse,
  handleProjectResponse,
  refreshProjects
} from '~/lib/projectsUtils'
import { Project } from '~/slices/projectSlice'

interface Params {
  references?: string[]
  search?: string
  orderBy?: string
  order?: 'ASC' | 'DESC'
  page?: number
}

const Projects = () => {
  const { t } = useTranslation()
  const {
    user,
    projects,
    sortingProjects,
    disableLoadMoreProjects,
    setPdfUrl,
    setSortingProjects,
    setDisableLoadMoreProjects,
    setTriggerDialog,
    setCurrentProjectReference
  } = useBoundStore((state: StoreState) => ({ ...state }))

  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 300)

  useEffect(() => {
    const params: Params = {}
    if (!user) {
      params.references = JSON.parse(
        localStorage.getItem('guestProjectReferences') || '[]'
      )
    }
    solarPanelClient
      .get('/projects', { params })
      .then(handleProjectResponse)
      .catch((error) => {
        Sentry.captureException(error)
        setDisableLoadMoreProjects(false)
      })
    setCurrentProjectReference('')
    return () => {
      setCurrentProjectReference('')
      setPdfUrl(undefined)
    }
  }, [])

  useEffect(() => {
    refreshProjects(debouncedSearch, sortingProjects)
  }, [debouncedSearch, sortingProjects])

  const loadMore = () => {
    setDisableLoadMoreProjects(true)
    const params: Params = {
      orderBy: sortingProjects.field,
      order: sortingProjects.ascending ? 'ASC' : 'DESC',
      page: projects.page + 1
    }
    if (debouncedSearch.length) {
      params.search = debouncedSearch
    }
    if (!user) {
      params.references = JSON.parse(
        localStorage.getItem('guestProjectReferences') || '[]'
      )
    }
    if (user) {
      solarPanelClient
        .get('/projects', { params })
        .then(handleLoadMoreProjectsResponse)
        .catch((error) => {
          Sentry.captureException(error)
          setDisableLoadMoreProjects(false)
        })
    }
  }

  return (
    <div className="h-full w-full">
      <div className="mb-10 flex w-full justify-between">
        <div className="ml-auto w-80">
          <ExternalInput
            name="search"
            placeholder={t('Sök...')}
            className="w-full"
            onChange={(event) => setSearch(event.target.value)}
            value={search}
          />
        </div>
      </div>
      <table className="mb-10 w-full border-collapse">
        <thead>
          <tr className="border-b border-grayscale-25 text-lg font-bold">
            <td className="whitespace-nowrap py-2 pr-3 text-sm font-bold uppercase">
              {t('Namn')}
            </td>
            <td className="min-w-[86px] whitespace-nowrap px-6 py-2 text-sm font-bold uppercase">
              {t('Referensnr')}
            </td>
            <td className="min-w-[86px] whitespace-nowrap px-6 py-2 text-sm font-bold uppercase">
              {t('Takytor')}
            </td>
            <td className="min-w-[196px] whitespace-nowrap px-3 py-2 text-sm font-bold uppercase">
              {t('Ägare')}
            </td>
            <td
              className="cursor-pointer whitespace-nowrap px-3 py-2 text-sm font-bold uppercase"
              onClick={() =>
                setSortingProjects({
                  field: 'updated_at',
                  ascending: !sortingProjects.ascending
                })
              }
            >
              {t('Senast ändrad')}{' '}
              <div className="ml-2 inline-block">
                <FontAwesomeIcon
                  className="h-[12px]"
                  icon={
                    sortingProjects.field === 'updated_at' &&
                    sortingProjects.ascending
                      ? faAngleUp
                      : faAngleDown
                  }
                />
              </div>
            </td>
            <td className="whitespace-nowrap py-4"></td>
            <td className="whitespace-nowrap py-4"></td>
          </tr>
        </thead>
        <tbody>
          {projects.userProjects.map((configuration: Project, key: number) => (
            <tr
              key={key}
              className="border-b border-grayscale-25 text-base"
            >
              <td className="w-full py-4 pr-3">
                <a
                  href={`#/parallel/${configuration.reference}`}
                  className="no-underline hover:underline"
                >
                  {configuration.name}
                </a>
              </td>
              <td className="min-w-[86px] whitespace-nowrap px-6 py-4">
                {configuration.reference}
              </td>
              <td className="min-w-[86px] whitespace-nowrap px-6 py-4">
                {configuration.numberOfRoofs}
              </td>
              <td className="min-w-[196] whitespace-nowrap px-3 py-4">
                {user ? `${user?.firstName} ${user?.lastName}` : t('Gäst')}
              </td>
              <td className="min-w-[180px] whitespace-nowrap px-3 py-4">
                {configuration.updatedAt}
              </td>
              <td>
                <div className="relative">
                  <div
                    className={cn(
                      'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full hover:bg-silver-25'
                    )}
                    data-tooltip-id={configuration.reference}
                    data-tooltip-content={t('Ladda ner PDF')}
                    data-tooltip-place="right"
                    data-tooltip-delay-show={500}
                    onClick={() => {
                      setCurrentProjectReference(configuration.reference)
                      setPdfUrl(configuration.pdfUrl)
                      setTriggerDialog('GenerateProjectReportDialog')
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowDownToLine} />
                  </div>
                  <Tooltip
                    id={configuration.reference}
                    style={{ borderRadius: 0 }}
                  />
                </div>
              </td>
              <td>
                <ProjectDropDown
                  className="block w-auto"
                  reference={configuration.reference}
                  pdfUrl={configuration.pdfUrl}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex flex-col items-center gap-4 pb-20">
        <div className="text-sm font-medium">{`${t('Visar')} ${
          projects.loaded
        } ${t('av')} ${projects.total}`}</div>
        <ProgressBar
          progress={(projects.loaded / projects.total) * 100}
          className="max-w-[244px]"
        />
        {projects.loaded < projects.total ? (
          <Button
            variant="ghost"
            onClick={loadMore}
            disabled={disableLoadMoreProjects}
          >
            {t('Ladda fler konfigurationer')}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default Projects

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog'
import { t } from 'i18next'
import { StoreState, useBoundStore } from '~/store'
import { useEffect, useState } from 'react'
import { FormGenerateRoofPDF } from '~/components/form/FormGenerateRoofPdf'

type Props = {
  roofUid: string
}

const GenerateRoofPdfDialog = ({ roofUid }: Props) => {
  const [open, setOpen] = useState(false)

  const { triggerDialog, setTriggerDialog } = useBoundStore(
    (state: StoreState) => ({
      triggerDialog: state.triggerDialog,
      setTriggerDialog: state.setTriggerDialog
    })
  )

  useEffect(() => {
    if (triggerDialog === 'GenerateRoofPdfDialog') {
      setOpen(true)
      setTriggerDialog('')
    } else if (open) {
      setOpen(false)
    }
  }, [triggerDialog])

  const handleGeneratePdfSubmit = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        setOpen(!open)
        setTriggerDialog('')
      }}
    >
      <DialogContent aria-describedby={t('Nedladdning för rapport')}>
        <DialogHeader>
          <DialogTitle className="text-lg">
            {t('Ladda ner')}
          </DialogTitle>
        </DialogHeader>
        <FormGenerateRoofPDF
          onFormSubmit={handleGeneratePdfSubmit}
          roofUid={roofUid}
        />
      </DialogContent>
    </Dialog>
  )
}

export default GenerateRoofPdfDialog

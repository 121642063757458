import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu'
import { Button } from '~/components/ui/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons'
import {
  faArrowDownToLine,
  faCircleInfo,
  faClone,
  faPenLine,
  faTrashCan
} from '@fortawesome/sharp-regular-svg-icons'
import { t } from 'i18next'
import { useState } from 'react'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '~/store'
import { cn } from '~/lib/utils'
import { duplicateProject } from '~/lib/projectsUtils'

type Props = {
  reference: string
  pdfUrl: string
  className?: string
}

const ProjectDropDown = ({ reference, pdfUrl, className }: Props) => {
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const { setPdfUrl, setTriggerDialog, setCurrentProjectReference } =
    useBoundStore(
      (state: StoreState) => ({
        setPdfUrl: state.setPdfUrl,
        setTriggerDialog: state.setTriggerDialog,
        setCurrentProjectReference: state.setCurrentProjectReference
      }),
      shallow
    )

  const handleNameChange = () => {
    setTriggerDialog('ProjectNameDialog')
  }

  const handleDuplicate = (reference: string) => {
    setDropDownOpen(false)
    duplicateProject(reference)
  }

  const handleDelete = () => {
    setTriggerDialog('DeleteProjectDialog')
  }

  return (
    <div className={cn('h-8 w-full', className)}>
      <DropdownMenu
        open={dropDownOpen}
        onOpenChange={setDropDownOpen}
        modal={false}
      >
        <DropdownMenuTrigger asChild>
          <Button
            className={cn(
              'ml-2 rounded-full text-lg hover:bg-silver-25 group-hover:hover:bg-silver-25',
              dropDownOpen && 'bg-silver-25'
            )}
            variant="ghost"
            size="icon"
            onClick={(event) => {
              setCurrentProjectReference(reference)
              event.stopPropagation()
            }}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="start"
          alignOffset={16}
          side="top"
          sideOffset={0}
        >
          <DropdownMenuGroup>
            <DropdownMenuItem
              onSelect={(event) => {
                event.stopPropagation()
                event.preventDefault()
              }}
              onClick={(event) => {
                event.stopPropagation()
                setPdfUrl(pdfUrl)
                setTriggerDialog('GenerateProjectReportDialog')
              }}
            >
              <FontAwesomeIcon icon={faArrowDownToLine} />
              <span className="ml-2">{t('Ladda ner')}</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={(event) => {
                event.stopPropagation()
                event.preventDefault()
                handleNameChange()
              }}
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <FontAwesomeIcon icon={faPenLine} />
              <span className="ml-2">{t('Ändra namn')}</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              className="mb-2"
              onSelect={(event) => {
                event.stopPropagation()
                event.preventDefault()
                handleDuplicate(reference)
              }}
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <FontAwesomeIcon icon={faClone} />
              <span className="ml-2">{t('Duplicera')}</span>
            </DropdownMenuItem>
            <div className="mb-2 border-b border-t border-grayscale-25">
              <DropdownMenuItem
                className="my-2"
                onSelect={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  setTriggerDialog('ProjectInfoDialog')
                }}
                onClick={(event) => {
                  event.stopPropagation()
                }}
              >
                <FontAwesomeIcon icon={faCircleInfo} />
                <span className="ml-2">{t('Mer information')}</span>
              </DropdownMenuItem>
            </div>
            <DropdownMenuItem
              className="text-red-100"
              onSelect={(event) => {
                event.preventDefault()
                handleDelete()
              }}
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <FontAwesomeIcon icon={faTrashCan} />
              <span className="ml-2">{t('Ta bort')}</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default ProjectDropDown

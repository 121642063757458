import { StateCreator } from 'zustand'
import { v4 as uuidv4 } from 'uuid'
import { inferRailDistanceFromPanel } from '../lib/utils'

interface Configurator {
  uid: string
  showPointerGuides: boolean
  showNewPanelAreaModal: boolean
  isDrawing: boolean
  panelInfo: PanelInfo
  panelInfoLow: PanelInfoLow
  currentPanel: SavedPanel | null
  activeArea: string | null
  isRedrawing: boolean
  editPanels: boolean
  mainPosition: { [key: string]: Position }
  isComplete: boolean
  scale: number
  showDialog: string | false
  dialogActions: {
    [key: string]: () => void
  } | null
  disabledDialogActions: {
    [key: string]: boolean
  } | null
  reference?: string
  // roofCoordinates: { [key: string]: number[][] }
  isLoadingResults: boolean
  isLoaded: boolean
  isEdited: boolean
  climateLoadsError?: string
  pdfUrl: string | undefined
  showResults: boolean
  showConditions: boolean
  isPositionDataValid: boolean
  isConfigurationComplete: boolean
  runImageCreation: boolean
  isApproved: boolean
  dialogMessage?: string
  triggerDialog:
    | ''
    | 'ProjectNameDialog'
    | 'SaveProjectNameDialog'
    | 'SaveProjectNameAndCloseDialog'
    | 'SaveProjectReportDialog'
    | 'ConfigurationNotPossibleDialog'
    | 'UnauthorizedDialog'
    | 'NotFoundDialog'
    | 'CannotBeResumedDialog'
    | 'SomethingWentWrongDialog'
    | 'InformationUpdatedDialog'
    | 'PasswordChangedDialog'
    | 'ProjectLockedDialog'
    | 'GenerateProjectReportDialog'
    | 'GenerateRoofPdfDialog'
    | 'ConfirmCloseDialog'
    | 'SaveQuestionDialog'
    | 'LoadingConfigurationDialog'
    | 'LoadingProjectDuplicationDialog'
    | 'ProjectInfoDialog'
    | 'DeleteProjectDialog'
    | 'LocaleStateProjectNameDialog'
    | 'DeletePanelDialog'
    | 'CreateEditPanelDialog'
}

export interface ConfiguratorSlice extends Configurator {
  toggleShowPointerGuides: () => void
  toggleShowNewPanelAreaModal: () => void
  setPanelInfo: (info: PanelInfo) => void
  setPanelInfoLow: (info: PanelInfoLow) => void
  setCurrentPanel: (uid: string | null, panels?: SavedPanel[]) => void
  currentPanel: SavedPanel | null
  setIsDrawing: (isDrawing: boolean) => void
  setActiveArea: (activeArea: string | null) => void
  setIsRedrawing: (redrawing: boolean) => void
  setEditPanels: (edit: boolean) => void
  setMainPosition: (mainPosition: { [key: string]: Position }) => void
  setScale: (scale: number) => void
  setShowDialog: (showDialog: string | false) => void
  setDialogActions: (
    dialogActions: {
      [key: string]: () => void
    } | null
  ) => void
  setDisabledDialogActions: (
    dialogActions: {
      [key: string]: boolean
    } | null
  ) => void
  setReference: (reference: string) => void
  setUid: (uid: string) => void
  resetConfigurator: () => void
  // setRoofCoordinates: (coordinates: number[][]) => void
  setIsLoadingResults: (isLoadingResults: boolean) => void
  setIsLoaded: (isLoaded: boolean) => void
  setIsEdited: (isEdited: boolean) => void
  setClimateLoadsError: (error?: string) => void
  setPdfUrl: (pdfUrl: string | undefined) => void
  setShowResults: (showResults: boolean) => void
  setShowConditions: (showConditions: boolean) => void
  setIsPositionDataValid: (isPositionDataValid: boolean) => void
  setIsApproved: (isApproved: boolean) => void
  setIsConfigurationComplete: (isConfigurationComplete: boolean) => void
  setRunImageCreation: (runImageCreation: boolean) => void
  setTriggerDialog: (
    triggerDialog: Configurator['triggerDialog'],
    dialogMessage?: string
  ) => void
}

const PanelMounting = localStorage.getItem('panelMounting') ?? '90-portrait'
const LowSlopePanelMounting = localStorage.getItem('lowSlopePanelMounting')
const panelSystem = localStorage.getItem('panelSystem') ?? 'east/west'

const panelWidth = 1000
const panelHeight = 1700
const panelWeight = 22
const panelRowGap = localStorage.getItem('panelRowGap')
  ? Number(localStorage.getItem('panelRowGap'))
  : 350

export const defaultPanelInfoValues = {
  uid: '',
  name: '',
  width: panelWidth,
  height: panelHeight,
  weight: panelWeight,
  widthMounted: panelWidth,
  heightMounted: panelHeight,
  mounting: PanelMounting,
  selectedRailDistance: inferRailDistanceFromPanel(panelWidth, panelHeight, {
    system: panelSystem,
    mounting: PanelMounting
  }),
  useSupportPlates:
    localStorage.getItem('useSupportPlates') === 'true' ? true : false,
  useThreeRails: localStorage.getItem('useThreeRails') == 'true' ? true : false,
  customRailDistanceIsSet: false,
  gap: 20
}

export const defaultLowSlopePanelInfoValues = {
  ...defaultPanelInfoValues,
  mounting: LowSlopePanelMounting ? LowSlopePanelMounting : '2',
  gapRow: panelRowGap ? panelRowGap : 350,
  gapTop: 82,
  system: panelSystem,
  useThreeRails: false, 
}

const initialState: Configurator = {
  uid: uuidv4(),
  showPointerGuides: false,
  showNewPanelAreaModal: false,
  isDrawing: false,
  editPanels: false,
  panelInfo: defaultPanelInfoValues,
  panelInfoLow: defaultLowSlopePanelInfoValues,
  currentPanel: null,
  activeArea: null,
  isRedrawing: false,
  mainPosition: {},
  isComplete: false,
  scale: 1,
  showDialog: false,
  dialogActions: null,
  disabledDialogActions: null,
  reference: undefined,
  isLoadingResults: false,
  isLoaded: false,
  isEdited: true,
  pdfUrl: undefined,
  showResults: false,
  showConditions: true,
  isPositionDataValid: false,
  isConfigurationComplete: false,
  runImageCreation: false,
  isApproved: false,
  dialogMessage: undefined,
  triggerDialog: ''
}

export const createConfiguratorSlice: StateCreator<ConfiguratorSlice> = (
  set
) => ({
  ...initialState,
  toggleShowPointerGuides: () =>
    set((state) => ({ showPointerGuides: !state.showPointerGuides })),
  toggleShowNewPanelAreaModal: () =>
    set((state) => ({ showNewPanelAreaModal: !state.showNewPanelAreaModal })),
  setPanelInfo: (panelInfo) => set(() => ({ panelInfo: panelInfo })),
  setPanelInfoLow: (panelInfo) => set(() => ({ panelInfoLow: panelInfo })),
  setCurrentPanel: (uid, panels) => {
    if (!panels) set(() => ({ currentPanel: null }))
    else
      set(() => ({
        currentPanel: panels.find((panel) => panel.uid === uid) ?? null
      }))
  },
  setIsDrawing: (isDrawing) => set(() => ({ isDrawing: isDrawing })),
  setActiveArea: (activeArea) => set(() => ({ activeArea: activeArea })),
  setIsRedrawing: (redrawing) => set(() => ({ isRedrawing: redrawing })),
  setEditPanels: (editPanels) => set(() => ({ editPanels })),
  setMainPosition: (mainPosition) => set(() => ({ mainPosition })),
  setScale: (scale) => set(() => ({ scale })),
  setShowDialog: (showDialog: string | false) => set(() => ({ showDialog })),
  setDialogActions: (
    dialogActions: {
      [key: string]: () => void
    } | null
  ) => set(() => ({ dialogActions })),
  setDisabledDialogActions: (
    disabledDialogActions: {
      [key: string]: boolean
    } | null
  ) => set(() => ({ disabledDialogActions })),
  setReference: (reference: string) => set(() => ({ reference })),
  setUid: (uid: string) => set(() => ({ uid })),
  resetConfigurator: () => {
    set((state) => {
      /**
       * system, mounting and selectedRailDistance is not reset here.
       * In ConfiguratorSlice these are set as default values based on localStorage,
       * but because ConfiguratorSlice is only executed once on complete page reload
       * these values gets out of sync with the actual state of the configurator.
       *
       * This should be rewritten and logic replaced with zustand persist.
       */
      return {
        ...initialState,
        panelInfoLow: {
          ...initialState.panelInfoLow,
          system: state.panelInfoLow.system,
          mounting: state.panelInfoLow.mounting,
          selectedRailDistance: state.panelInfoLow.selectedRailDistance
        },
        panelInfo: {
          ...initialState.panelInfo,
          mounting: state.panelInfo.mounting,
          selectedRailDistance: state.panelInfo.selectedRailDistance
        },
        uid: uuidv4()
      }
    })
  },
  setIsLoadingResults: (isLoadingResults: boolean) =>
    set(() => ({ isLoadingResults })),
  setIsLoaded: (isLoaded: boolean) => set(() => ({ isLoaded })),
  setIsEdited: (isEdited: boolean) => set(() => ({ isEdited })),
  setClimateLoadsError: (climateLoadsError?: string) =>
    set(() => ({ climateLoadsError })),
  setTriggerDialog: (
    triggerDialog: Configurator['triggerDialog'],
    dialogMessage?: string
  ) => set(() => ({ triggerDialog, dialogMessage })),
  setPdfUrl: (pdfUrl: string | undefined) => set(() => ({ pdfUrl })),
  setShowResults: (showResults: boolean) =>
    set(() => ({
      showResults
    })),
  setShowConditions: (showConditions: boolean) =>
    set(() => ({
      showConditions
    })),
  setIsPositionDataValid: (isPositionDataValid: boolean) =>
    set(() => ({
      isPositionDataValid
    })),
  setIsConfigurationComplete: (isConfigurationComplete: boolean) => {
    set({ isConfigurationComplete: isConfigurationComplete })
  },
  setRunImageCreation: (runImageCreation: boolean) =>
    set(() => ({
      runImageCreation
    })),
  setIsApproved: (isApproved: boolean) =>
    set(() => ({
      isApproved
    }))
})

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog'
import { t } from 'i18next'
import { StoreState, useBoundStore } from '~/store'
import { useEffect, useState } from 'react'
import SaveNameProjectForm from '../form/SaveNameProjectForm'
import { FormGeneratePDF } from '../form/FormGeneratePdf'

const SaveReportProjectDialog = () => {
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(1)

  const handleFormSubmit = () => {
    setStep(2)
  }

  const { conditions, triggerDialog, setTriggerDialog } = useBoundStore(
    (state: StoreState) => ({
      conditions: state.conditions,
      triggerDialog: state.triggerDialog,
      setTriggerDialog: state.setTriggerDialog
    })
  )

  useEffect(() => {
    if (triggerDialog === 'SaveProjectReportDialog') {
      setOpen(true)
      setStep(1)
    } else if (open) {
      setOpen(false)
      setTriggerDialog('')
    }
  }, [triggerDialog])

  const handleGeneratePdfSubmit = () => {
    setOpen(false)
    setTriggerDialog('')
  }

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        setOpen(!open)
        setTriggerDialog('')
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-base">
            {t('Steg {{step}} av {{totalSteps}}', {
              step: step,
              totalSteps: 2
            })}
          </DialogTitle>
        </DialogHeader>
        {step === 1 ? (
          <>
            <h3 className="heading-s mb-4">
              {conditions.name ? t('Spara projekt') : t('Projektnamn')}
            </h3>
            <p className="mb-6">
              {t('Spara projektet för att kunna generera en rapport.')}
            </p>
            <SaveNameProjectForm
              submitButtonText={t('Spara och fortsätt')}
              onFormSubmit={handleFormSubmit}
              hideName={!!conditions.name}
            />
          </>
        ) : (
          <>
            <h3 className="heading-s mb-6">{t('Ladda ner')}</h3>
            <FormGeneratePDF onFormSubmit={handleGeneratePdfSubmit} />
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default SaveReportProjectDialog

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog'
import { t } from 'i18next'
import { StoreState, useBoundStore } from '~/store'
import { useEffect, useState } from 'react'
import { FormGeneratePDF } from '../form/FormGeneratePdf'

const GenerateProjectReportDialog = () => {
  const [open, setOpen] = useState(false)

  const { triggerDialog, setTriggerDialog } = useBoundStore(
    (state: StoreState) => ({
      triggerDialog: state.triggerDialog,
      setTriggerDialog: state.setTriggerDialog
    })
  )

  useEffect(() => {
    if (triggerDialog === 'GenerateProjectReportDialog') {
      setOpen(true)
      setTriggerDialog('')
    } else if (open) {
      setOpen(false)
    }
  }, [triggerDialog])

  const handleGeneratePdfSubmit = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        setOpen(!open)
        setTriggerDialog('')
      }}
    >
      <DialogContent aria-describedby={t('Nedladdning för rapport')}>
        <DialogHeader>
          <DialogTitle className="text-lg">
            {t('Ladda ner')}
          </DialogTitle>
        </DialogHeader>
        <FormGeneratePDF onFormSubmit={handleGeneratePdfSubmit} />
      </DialogContent>
    </Dialog>
  )
}

export default GenerateProjectReportDialog
